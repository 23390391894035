import React, { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Cancel, UploadIcon } from "../../Assets/Svgs";
import { GButton } from "../Button/button";
import { devices } from "../../Utils";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

export const GImageUpload = ({
  register,
  trigger,
  errors,
  files,
  isDownloadingImages,
  setFiles,
  single,
  size,
  maxHeight,
  maxWidth,
  name,
  defaultImages
}) => {
  const [touched, setTouched] = useState(false);
  const inputRef = useRef();
  const dragAreaRef = useRef(null)

  const { ref: registerRef, ...rest } = register(name, {
    required: "Please upload a file",
    validate: () => console.log("validating")
  });


  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      const newFiles = [...e.dataTransfer.files];
      if (single) {
        setFiles(newFiles);
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }
      setTouched(true);
    },
    [setFiles, single]
  );
  const validateFiles = async (files, setFiles, maxWidth, maxHeight) => {
    if (!files || files.length === 0) {
      toast.error("Please upload a file");
      return false;
    }

    const maxSize = 1024 * 1024; // 1 MB in bytes

    const invalidFiles = files.filter((file) => file.size > maxSize);

    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        toast.error(`File "${file.name}" exceeds the limit of 1MB`);
      });
      return false;
    }

    if (maxWidth && maxHeight) {
      for (const file of files) {
        const img = document.createElement('img');
        const loadImage = new Promise((resolve, reject) => {
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            if (width > maxWidth || height > maxHeight) {
              reject("Image dimensions exceed the limit");
              toast.error(`Image dimensions of "${file.name}" exceed the limit`);
            } else {
              resolve();
            }
          };
          img.onerror = () => {
            reject("Error loading image");
            toast.error(`Error loading image "${file.name}"`);
          };
        });
        img.src = URL.createObjectURL(file);

        try {
          await loadImage;
        } catch (error) {
          return false;
        }
      }
    }

    setFiles(files);
    return true;
  };

  const handleFileSelect = async (e) => {
    const newFiles = [...e.target.files];
    setTouched(true);
    await validateFiles(newFiles, setFiles, maxWidth, maxHeight);

  };


  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  useEffect(() => {
    if (touched) {
      trigger();
    }
  }, [files, touched, trigger]);


  useEffect(() => {
    if (defaultImages && defaultImages?.length > 0) {
      setFiles(defaultImages)
    }
  }, [defaultImages, setFiles])


  useEffect(() => {
    // Get the current DOM node from the ref
    const dragArea = dragAreaRef.current;

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Check if the dragArea is available before adding event listeners
    if (dragArea) {
      dragArea.addEventListener("dragenter", preventDefaults, false);
      dragArea.addEventListener("dragleave", preventDefaults, false);
      dragArea.addEventListener("dragover", preventDefaults, false);
      dragArea.addEventListener("drop", handleDrop, false);

      // Cleanup event listeners
      return () => {
        dragArea.removeEventListener("dragenter", preventDefaults);
        dragArea.removeEventListener("dragleave", preventDefaults);
        dragArea.removeEventListener("dragover", preventDefaults);
        dragArea.removeEventListener("drop", handleDrop);
      };
    }
  }, [handleDrop]);

  if (isDownloadingImages)
    return <ClipLoader color="#ff4623" />

  return (
    <Container>
      {
        !files.length > 0 &&
        <UploadBox id="drag-area" ref={dragAreaRef} $isError={errors.files}>
          <UploadIcon />
          <UploadTxt>
            Click to upload <span>or drag and drop</span>
          </UploadTxt>
          <AcceptTxt>{`SVG, PNG, JPG or GIF (max. ${size || "800x400px"})`}</AcceptTxt>
          <OrTxt>OR</OrTxt>
          <Input
            ref={(e) => {
              registerRef(e);
              inputRef.current = e;
            }}
            {...rest}
            type="file"
            multiple={!single}
            onChange={handleFileSelect}
            accept="image/*"
          />
          <GButton
            type={"button"}
            label={"Browse files"}
            width={`160px`}
            onClick={handleClick}
          />
          {errors.files && <Error>{errors.files.message}</Error>}
        </UploadBox>
      }

      {files.length > 0 && (
        <PreviewWrapper>
          <FileCount>{`${files.length} files uploaded`}</FileCount>
          <ImagesWrapper>
            {files?.map((file, index) => (
              <PreviewImage key={index}>
                <CancelWrapper onClick={() => removeFile(index)}>
                  <Cancel />
                </CancelWrapper>
                {file?.type === "video" ? (
                  <Video src={URL.createObjectURL(file)} />
                ) : (
                  <Image src={URL.createObjectURL(file)} alt={file.name} />
                )}
              </PreviewImage>
            ))}
          </ImagesWrapper>
        </PreviewWrapper>
      )}
    </Container>
  );
};




const Container = styled.div``;

const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: ${({ $isError }) =>
    $isError ? `1.5px dashed #d21f37` : `1.5px dashed #d0d5dd`};
  background: var(--Shade-White, #fff);
  transition: all 0.25s ease;

  & > button {
    font-family: "Barlow";
  }
`;

const UploadTxt = styled.p`
  color: var(--Primary-500, #ff4623);
  font-family: "Barlow";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  margin: 16px 0 8px;

  & > span {
    color: var(--Grey-600, #475367);
    font-family: "Barlow";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`;

const AcceptTxt = styled.p`
  color: var(--Grey-400, #98a2b3);
  font-family: "Barlow";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
`;

const OrTxt = styled.p`
  color: var(--Grey-400, #98a2b3);
  font-family: "Barlow";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  margin: 18px 0;
`;

const Input = styled.input`
  display: none;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

const FileCount = styled.p`
  color: var(--Grey-400, #98a2b3);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
`;

const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media ${devices.mobileL} {
    gap: 10px;
  }
`;

const PreviewImage = styled.div`
  position: relative;
  width: 128px;
  height: 128px;

  @media ${devices.mobileL} {
    width: 30%;
    height: 100px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1.753px solid var(--Black-300, #626262);
  object-fit: cover;
`;

const Video = styled.video`
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1.753px solid var(--Black-300, #626262);
`;

const Error = styled.p`
  color: #d21f37;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin-top: 10px;
`;

const CancelWrapper = styled.div`
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  cursor: pointer;

  & > svg {
    width: 15px;
    height: 15px;
    top: 5px;
    right: 5px;
  }

  @media ${devices.mobileL} {
    top: 5px;
    right: 5px;
  }
`;
