import styled from "styled-components";
import { SellerNavbar, SellerSidebar } from "./components";
import { devices } from "../../Utils";

export const DashboardLayout = ({ children }) => {
  return (
    <Container>
      <SellerSidebar />
      <RightContainer>
        <SellerNavbar />
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </RightContainer>
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  width: 100vw;
  background: #f9f9f9;
`;

const RightContainer = styled.div`
  flex-grow: 1;
  padding: 0 64px 0px 30px;

  @media ${devices.laptop} {
    padding: 0 20px 0px 30px;
  }
`;

const ChildrenWrapper = styled.div`
  height: calc(100vh - 92px);
  overflow-y: scroll;
  padding: 40px 0 30px;
  max-width: 1440px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  @media ${devices.laptop} {
    height: calc(100vh - 72px);
  }
`;
