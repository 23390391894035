import React, { useState } from "react";
import styled from "styled-components";
import { GButton, GTextField } from "../../../../Ui_elements";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordSchema } from "../../Account/components/validation";
import SuccessModal from "../../Orders/components/modals/successModal";
import { devices } from "../../../../Utils";
import { updatePassword } from "../../../../Urls";
import { useApiSend } from "../../../../Hooks";
import { toast } from "react-hot-toast";

const Password = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { mutate: resetPasswordMutate, isPending } = useApiSend(
    updatePassword,
    () => {
      setIsSuccessModalOpen(true);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PasswordSchema),
  });

  const handleClose = () => {
    setIsSuccessModalOpen(false);
    reset();
  };

  const onSubmit = (values) => {
    resetPasswordMutate({
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    });
  };

  return (
    <Container>
      <Top>
        <Title>Password</Title>
      </Top>
      <Bottom>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <TextWrapper>
              <Label>Old Password</Label>
              <GTextField
                id="oldPassword"
                placeholder="Old password"
                inputType="password"
                name="oldPassword"
                register={register}
                error={errors.oldPassword}
                errorText={errors.oldPassword && errors.oldPassword.message}
                required
              />
            </TextWrapper>
            <TextWrapper>
              <Label>New Password</Label>
              <GTextField
                id="newPassword"
                placeholder="New password"
                inputType="password"
                name="newPassword"
                register={register}
                error={errors.newPassword}
                errorText={errors.newPassword && errors.newPassword.message}
                required
              />
            </TextWrapper>
            <TextWrapper>
              <Label>Confirm password</Label>
              <GTextField
                id="confirmPassword"
                placeholder="Confirm password"
                inputType="password"
                name="confirmPassword"
                register={register}
                error={errors.confirmPassword}
                errorText={
                  errors.confirmPassword && errors.confirmPassword.message
                }
                required
              />
            </TextWrapper>
          </InputWrapper>
          <GButton label={`Save`} width={`204px`} />
        </Form>
      </Bottom>
      <SuccessModal
        handleClose={handleClose}
        isOpen={isSuccessModalOpen}
        title={`Password updated succesfully`}
        subtitle={`Your new password has been set`}
      />
    </Container>
  );
};

export default Password;

const Container = styled.div`
  width: 100%;
  flex-shrink: 0;
  border: 1px solid var(--Black-50, #e8e8e8);
  background: var(--White, #fefefe);
`;

const Top = styled.div`
  padding: 30px 35px 20px;
  border-bottom: 1px solid #e8e8e8;
`;

const Title = styled.p`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 35px 35px 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media ${devices.tablet} {
    align-items: start;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 40px 45px;
  margin-bottom: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 47%;

  @media ${devices.laptop} {
    width: 46%;
  }

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const Label = styled.p`
  color: var(--Black-100, #b6b6b6);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
