import styled from "styled-components";
import { Logo } from "../../../../Assets/Svgs";
import { sideBarMenus } from "../../data";
import { MenuItem } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { devices } from "../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { logout, setSideBar, setUser } from "../../../../Redux/Reducers";
import { useDeviceCheck } from "../../../../Hooks";
import Drawer from "@mui/material/Drawer";

export const SellerSidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceCheck();
  const isOpen = useSelector((state) => state?.sidebar);
  return (
    <>
      {isMobile ? (
        <Drawer open={isOpen} onClose={() => dispatch(setSideBar(false))}>
          <Container>
            <Logo onClick={() => navigate("/")} />
            <MenuWrapper>
              {sideBarMenus.map((item) => (
                <MenuItem
                  key={item?.id}
                  item={item}
                  active={pathname.includes(item?.path)}
                />
              ))}
            </MenuWrapper>
            <Logout
              onClick={() => {
                dispatch(logout(null));
                navigate("/");
              }}
            >
              Log out
            </Logout>
          </Container>
        </Drawer>
      ) : (
        <Container>
          <Logo onClick={() => navigate("/")} />
          <MenuWrapper>
            {sideBarMenus.map((item) => (
              <MenuItem
                key={item?.id}
                item={item}
                active={pathname.includes(item?.path)}
              />
            ))}
          </MenuWrapper>
          <Logout
            onClick={() => {
              dispatch(setUser(null));
              dispatch(logout(null));
              navigate("/");
            }}
          >
            Log out
          </Logout>
        </Container>
      )}
    </>
  );
};

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 84px;
  width: 330px;
  height: 100vh;
  padding: 20px 70px 82px;
  background: #fefefe;

  & > svg {
    width: 168px;
    height: 72px;
    flex-shrink: 0;
    cursor: pointer;
  }

  @media ${devices.laptop} {
    gap: 50px;
    padding: 14px 0px 30px 20px;
    width: 170px;
    & > svg {
      width: 120px;
      height: 50px;
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;

  @media ${devices.laptop} {
    gap: 30px;
  }
`;

const Logout = styled.p`
  color: var(--Ginger-Error, #ce0303);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  margin-top: auto;
  cursor: pointer;

  @media ${devices.laptop} {
    font-size: 17px;
  }
`;
