import React from "react";
import { GModal } from "../../../../../Ui_elements";
import styled from "styled-components";
import { LogoMarkIcon, SuccessIcon } from "../../../../../Assets/Svgs";
import { devices } from "../../../../../Utils";

const SuccessModal = ({ isOpen, handleClose, title, subtitle }) => {
  return (
    <GModal open={isOpen} handleClose={handleClose}>
      <Container>
        <Header>
          <SuccessIcon />
          <BgIcon />
        </Header>
        <ContentWrapper>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Btn onClick={handleClose}>Continue</Btn>
        </ContentWrapper>
      </Container>
    </GModal>
  );
};

export default SuccessModal;

const Container = styled.div`
  width: 40vw;
  max-width: 700px;
  border-radius: 8px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 206px;
  flex-shrink: 0;
  background: #f6fff3;
  padding-top: 66px;
  overflow: hidden;

  & > svg:first-of-type {
    z-index: 1;
  }
`;

const BgIcon = styled(LogoMarkIcon)`
  position: absolute;
  left: -25px;
  top: 22px;
  z-index: 0;

  path {
    stroke: #bde0b1;
  }

  @media ${devices.laptop} {
    left: -121px;
    top: 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 36px 0 39px;
`;

const Title = styled.p`
  color: var(--Black-500, #151515);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

const Subtitle = styled.p`
  color: var(--Black-300, #626262);
  text-align: center;
  width: 55%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media ${devices.laptop} {
    width: 85%;
  }
`;

const Btn = styled.div`
  display: flex;
  width: 372px;
  height: 56px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(
    --Black-500,
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    #151515
  );
  color: var(--Shade-White, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  cursor: pointer;
  margin-top: 19px;
`;
