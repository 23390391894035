import { request } from "../Utils";


export const verifyKyc = (data, id) =>
  request({
    url: `store/kyc/${id}`,
    method: "POST",
    data,
  });

export const getKycVerification = (id) =>
  request({
    url: `store/kyc/${id}/getKycStatus`,
    method: "GET",
  });
