import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { DashboardLayout } from "../Layouts";
import { verifyKYC } from "../Redux/Reducers";
import { PageLoader } from "../Ui_elements";
import { authRoutes, routes } from "./routes";

export const AppRoutes = () => {
  const location = useLocation();
  const kycVerified = useSelector((state) => state?.user?.isKycVerified);

  // Scroll to top when location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {kycVerified
        ? routes.map(({ path, element: Element, hasLayout }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<PageLoader />}>
                  {hasLayout ? (
                    <Element />
                  ) : (
                    <DashboardLayout>
                      <Element />
                    </DashboardLayout>
                  )}
                </Suspense>
              }
            />
          ))
        : authRoutes.map(({ path, element: Element, hasLayout }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<PageLoader />}>
                  {hasLayout ? (
                    <Element />
                  ) : (
                    <DashboardLayout>
                      <Element />
                    </DashboardLayout>
                  )}
                </Suspense>
              }
            />
          ))}
    </Routes>
  );
};
