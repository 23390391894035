import _ from "lodash";
import { currencyCode, currencySymbol } from "./constants";


const BASE_URL = `https://api.gingerme.io/v1`;

export const formatUrlName = (url) => {
  const removePercentage = url.replace(/%20/g, " ");
  let removeUnderscoreHyphen = removePercentage.replace(/_/g, " ");
  removeUnderscoreHyphen = removeUnderscoreHyphen.replace(/-/g, " ");
  const decoded = decodeURIComponent(
    removeUnderscoreHyphen.replace(/\+/g, " ")
  );
  return _.capitalize(decoded);
};

export function bytesForHuman(sizeBytes) {
  const UNITS = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte",
  ];
  const BYTES_PER_KB = 1000;
  let size = Math.abs(Number(sizeBytes));

  let u = 0;
  while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
    size /= BYTES_PER_KB;
    ++u;
  }
  return new Intl.NumberFormat([], {
    style: "unit",
    unit: UNITS[u],
    unitDisplay: "short",
    maximumFractionDigits: 1,
  }).format(size);
}

export const formatImage = (url) => {
  const base = BASE_URL.split("/")
    .filter((x) => x)
    .slice(0, 2)
    .join("//");
  return `${base}/${url}`;
};


const formatWithCommas = (amount) => {
  const isWholeNumber = Number.isInteger(amount);
  const amt = isWholeNumber ? amount.toString() : amount.toFixed(3);
  let parts = amt.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const convertAmount = (amount, conversionRate) => {
  return amount * conversionRate;
};

export const formatAmount = (
  amount,
  currency = currencyCode.USD,
  conversionRate = 1
) => {
  if (!amount) return "";

  const symbol =
    currency === currencyCode.NGN ? currencySymbol.NGN : currencySymbol.USD;
  const convertedAmount = convertAmount(amount, conversionRate);
  const formattedAmount = formatWithCommas(convertedAmount);

  return `${symbol} ${
    formattedAmount.length > 12
      ? formattedAmount.substring(0, 12)
      : formattedAmount
  }`;
};


export const orderStatusMapping = {
  pending: "Ongoing",
  paid: "Ongoing",
  processed: "Processed",
  shipped: "In transit",
  completed: "Delivered",
  cancelled: "Cancelled",
};

export const formatOrderStatus = (status) => {
  const lowercaseStatus = status?.toLowerCase();
  return orderStatusMapping[lowercaseStatus] || "Ongoing";
};

export const formatCardNumber = (cardNumber) => {
  if (cardNumber) {
    const numericOnly = cardNumber?.replace(/\D/g, "");
    const cardNumberFormat = /(\d{4})(\d{4})(\d{4})(\d{4})/;
    const formattedCardNumber = numericOnly.replace(
      cardNumberFormat,
      "$1 $2 $3 $4"
    );

    return formattedCardNumber;
  }
};

export const formatAddress = (data) => {
  return `${data?.line1}, ${data?.city}, ${data?.state}, ${data?.country}`;
};

export const generateUrlParams = (obj) => {
  let generatedUrl = ``;
  const arrayOfObjectKeys = Object.keys(obj);
  arrayOfObjectKeys.forEach((key) => {
    if (obj[key] || obj[key] === false) {
      generatedUrl += `${key}=${obj[key]}&`;
    }
  });
  return generatedUrl;
};

export const generateQueryKey = (baseKey, searchFilter) => {
  const searchFilterString = JSON.stringify(searchFilter);
  const queryKey = `${baseKey}-${searchFilterString}`;

  return queryKey;
};

export const filterSearchParams = (searchFilter) => {
  const filteredParams = {};

  if (searchFilter) {
    Object.entries(searchFilter).forEach(([key, value]) => {
      if (value !== "" && value !== null) {
        filteredParams[key] = value;
      }
    });
    return filteredParams;
  }
};

export const truncateText = (text, maxLength) => {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

export const amountToWords = (amount) => {
  const singleDigits = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teenDigits = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensDigits = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const suffixes = ["", "Thousand", "Million", "Billion", "Trillion"];

  function convertThreeDigits(num) {
    let words = "";
    if (num >= 100) {
      words += singleDigits[Math.floor(num / 100)] + " Hundred ";
      num %= 100;
    }
    if (num >= 10 && num <= 19) {
      words += teenDigits[num - 10] + " ";
    } else if (num >= 20) {
      words += tensDigits[Math.floor(num / 10)] + " ";
      num %= 10;
    }
    if (num > 0) {
      words += singleDigits[num] + " ";
    }
    return words;
  }

  // Function to convert the amount to words
  function convert(amount) {
    if (amount === 0) return "Zero";

    let words = "";
    let index = 0;

    do {
      const chunk = amount % 1000;
      if (chunk !== 0) {
        words = convertThreeDigits(chunk) + suffixes[index] + " " + words;
      }
      index++;
      amount = Math.floor(amount / 1000);
    } while (amount > 0);

    return words.trim();
  }

  return convert(amount);
};

export function checkIfFilesAreTooBig(files) {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    const sizeInMB = file.size / 1024 / 1024; // Convert size to megabytes
    if (sizeInMB > 3) {
      // Check if size exceeds 2MB
      valid = false;
    }
  }
  return valid;
}

export function checkIfImagesAreCorrectType(files) {
  let valid = true;
  if (files[0]) {
    const file = files[0];
    if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
      valid = false;
    }
  }
  return valid;
}

export const formatImageToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const extractCategories = (data) => {
  if (data) {
    return data?.map(({ _id, name }) => ({ value: _id, label: name }));
  }
};

export const extractBrands = (data) => {
  return data?.map(({ _id, name }) => ({ value: _id, label: name }));
};

export const extractSubCategories = (data) => {
  if (data) {
    return data?.reduce((acc, category) => {
      const subCategories = category.subCategories.map(({ _id, name }) => ({
        value: _id,
        label: name,
      }));
      return [...acc, ...subCategories];
    }, []);
  }
};

export const percentages = [
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  { label: "30%", value: 30 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
  { label: "60%", value: 60 },
  { label: "70%", value: 70 },
  { label: "80%", value: 80 },
  { label: "90%", value: 90 },
  { label: "100%", value: 100 },
];

export const downloadPreviewImage = async (url) => {
  const newUrl = url;
  try {
    const response = await fetch(newUrl);
    const blob = await response.blob();
    const filename = newUrl.substring(newUrl.lastIndexOf("/") + 1);
    return new File([blob], filename, { type: "image/*" });
  } catch (error) {
    return null;
  }
};

export const downloadPreviewImage2 = async (url) => {
  const newUrl = url;
  try {
    const response = await fetch(newUrl);
    const blob = await response.blob();
    const filename = newUrl.substring(newUrl.lastIndexOf("/") + 1);
    const href = URL.createObjectURL(blob);
    // return href
  } catch (e) {
    console.log(e);
  }
};

export const getOrderStatus = (hash) => {
  const hashStatus = hash.split("#").pop();
  return hashStatus.toUpperCase();
};

export const formatSelectItems = (items, label, value) => {
  return items?.length > 0
    ? items.map((sessionData) => {
        const newObj = {};
        if (Array.isArray(value)) {
          value.forEach((item) => {
            newObj[item] = sessionData[item];
          });
        }
        return {
          value: Array.isArray(value) ? newObj : sessionData[value],
          label: Array.isArray(label)
            ? `${sessionData[label[0]]} (${sessionData[label[1]]})`
            : sessionData[label],
        };
      })
    : [];
};
