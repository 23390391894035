import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      state = action.payload;
      return state;
    },
    setUserEmail: (state, action) => {
      state = {
        ...state,
        userEmail: action.payload,
      };
      return state;
    },
    setTokenOnRefresh: (state, action) => {
      state = {
        ...state,
        accessToken: action.payload,
      };
      return state;
    },
    logout: (state, action) => {
      state = action.payload;
      return state;
    },
    verifyKYC: (state, action) => {
      state = {
        ...state,
        isKycVerified: action.payload,
      };
      return state;
    },
    setStore: (state, action) => {
      state = {
        ...state,
        storeId: action.payload?._id,
        store: action.payload,
      };
      return state;
    },
    setStoreStatus: (state, action) => {
      state = {
        ...state,
        isStoreVerified: action.payload,
      };
      return state;
    },
  },
});

const { actions, reducer: userReducer } = userSlice;

const {
  setUser,
  setUserEmail,
  setTokenOnRefresh,
  logout,
  verifyKYC,
  setStore,
  setStoreStatus,
} = actions;

export {
  userReducer,
  setUser,
  setUserEmail,
  setTokenOnRefresh,
  logout,
  verifyKYC,
  setStore,
  setStoreStatus,
};
