import styled from 'styled-components'
import { EmptyCubeIcon } from '../../../Assets/Svgs'

export const Notifications = () => {
    return (
        <Container>
            <EmptyState />
        </Container>
    )
}

const EmptyState = () => <EmptyContainer>
    <Icon />
    <p>You do not have notifications at this time</p>
</EmptyContainer>




const Container = styled.div`
    max-height: 20vw;
    height: 20vw;
    width: 20vw;
    overflow-y: auto;
`
const EmptyContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:5px;

    p{
        font-size: 0.7rem;
    }
`

const Icon = styled(EmptyCubeIcon)`
    width: 20px;
    height: 20px;
`