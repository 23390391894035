import styled from "styled-components";
import { ChatIcon } from "../../../Assets/Svgs";
import Avatar from "@mui/material/Avatar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { devices } from "../../../Utils";
import { PopMenu } from "../../../Ui_elements";
import { Notifications } from "./notifications";
import { Menu } from "@mui/icons-material";
import { useApiGet, useDeviceCheck } from "../../../Hooks";
import { setSideBar } from "../../../Redux/Reducers";
import { getUser } from "../../../Urls";
import { profileHash } from "../../../Pages/Private/Profile/components/data";

export const SellerNavbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = pathname.split("/").slice(0, 3).pop();
  const formatttedPath = path.split("_").join(" ");
  const dispatch = useDispatch();
  const { isMobile } = useDeviceCheck();

  const { data: user, isLoading: isLoadingUser } = useApiGet(
    ["get-user-data"],
    () => getUser(),
    {
      enabled: true,
    }
  );

  return (
    <Container>
      {isMobile && (
        <MenuContainer
          onClick={() => {
            dispatch(setSideBar(true));
          }}
        >
          <Menu />
        </MenuContainer>
      )}
      <Title>{formatttedPath}</Title>
      <RightSide>
        <AvatarSearch>
          <AvatarContainer
            onClick={() =>
              navigate({ hash: profileHash.personal, pathname: "/profile" })
            }
          >
            <Avatar
              sx={{
                bgcolor: "var(--primary-color)",
                width: `22px`,
                height: `22px`,
              }}
              alt={user?.firstName}
              src="/broken-image.jpg"
            />
            <p>{`${user?.firstName} ${user?.lastName}`}</p>
          </AvatarContainer>
        </AvatarSearch>

        <PopMenu isComponent Component={Notifications}>
          <NotificationContainer>
            {/* <Badge
              badgeContent={10}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "var(--primary-color)",
                },
              }}
            > */}
            <ChatIcon />
            {/* </Badge> */}
            <p>Notification</p>
          </NotificationContainer>
        </PopMenu>
      </RightSide>
    </Container>
  );
};

const Container = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 20px;
  border-bottom: 1px solid #e8e8e8;

  @media ${devices.laptop} {
    padding: 12px 0 10px;
  }
`;

const MenuContainer = styled.div`
  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
  }
`;

const Title = styled.h1`
  color: var(--Black-500, #151515);
  font-family: Barlow;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 40.8px */
  text-transform: capitalize;

  @media ${devices.laptop} {
    font-size: 20px;
  }

  @media ${devices.tablet} {
    font-size: 1rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1.8rem;

  @media ${devices.tablet} {
    gap: 9px;
    margin-right: 20px;
  }
`;

const AvatarSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #e8e8e8;
  padding-right: 1rem;
  gap: 0.5rem;
  height: 48px;

  @media ${devices.laptop} {
    p {
      font-size: 15px;
    }
  }

  @media ${devices.tablet} {
    p {
      display: none;
    }
  }
`;

const NotificationContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  @media ${devices.laptop} {
    p {
      font-size: 15px;
    }
  }

  @media ${devices.tablet} {
    p {
      display: none;
    }
  }
`;
