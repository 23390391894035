import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components'
import { devices } from '../../Utils';


export const PopMenu = ({ menuItems, children, position, isComponent, Component, onClick, otherProps }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Wrapper
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={(e) => {
                    handleClick(e)
                    onClick && onClick()
                }
                }
            >
                {children}
            </Wrapper>
            <Menu
                elevation={2}
                id="customized-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: position === 'above' ? 'top' : 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: position === 'above' ? 'bottom' : 'top',
                    horizontal: 'center',
                }}
                {...otherProps}
            >
                {
                    isComponent ?
                        <Component />
                        :
                        (
                            menuItems?.map((menuItem, index) => (
                                <CustomMenuItem
                                    key={index}
                                    menuItem={menuItem.item}
                                    action={menuItem.action}
                                    handleClose={handleClose}
                                />
                            ))
                        )

                }

            </Menu>
        </>
    );
};

const CustomMenuItem = ({ menuItem, action, handleClose }) => {
    const handleItemClick = () => {
        action();
        handleClose();
    };

    return (
        <MenuContainer>
            <MenuItem onClick={handleItemClick}>
                <BorderItem>
                    <p>{typeof (menuItem) === "function" ? menuItem() : menuItem}</p>
                </BorderItem>

            </MenuItem>
        </MenuContainer>

    );
};

const Wrapper = styled.div`
    height: auto;
    cursor: pointer;
`

const MenuContainer = styled.div`
    width: auto;
    border-radius: 30px;
`


const BorderItem = styled.div`
    @media ${devices.laptop}{
        p{font-size: 0.8rem;}
    }

    /* border-bottom: 1px solid red; */
`