import { request } from "../Utils";

export const createStore = (data, id) =>
  request({
    url: `sellers/${id}/stores`,
    method: "POST",
    data,
  });

export const getSellerStore = (sellerId) =>
  request({
    url: `sellers/${sellerId}/stores`,
    method: "GET",
  });

export const setStoreDiscount = (data, storeId) =>
  request({
    url: `store/${storeId}/setting/discount`,
    method: "POST",
    data,
  });

export const setMinSpend = (data, storeId) =>
  request({
    url: `store/${storeId}/setting/update_minimum_spend`,
    method: "PUT",
    data,
  });

export const setStoreImages = (data, storeId) =>
  request({
    url: `store/${storeId}/setting`,
    method: "PUT",
    data,
  });

export const updateStoreDetails = (data, storeId) =>
  request({
    url: `store/${storeId}`,
    method: "PUT",
    data,
  });

export const getStoreDetails = (storeId) =>
  request({
    url: `store/${storeId}`,
    method: "GET",
  });

export const getSellerShoppingConfig = (storeId) =>
  request({
    url: `store/${storeId}/get_store_config`,
    method: "GET",
  });

export const getStoreShoppingConfigDiscounts = () =>
  request({
    url: `store/shopping-config`,
    method: "GET",
  });

export const deleteStoreDiscount = (data, storeId) =>
  request({
    url: `store/${storeId}/setting/discount`,
    method: "DELETE",
    data,
  });

export const getBankAccountDetails = (storeId) =>
  request({
    url: `store/${storeId}/account`,
    method: "GET",
  });

export const createBankAccount = (data, storeId) =>
  request({
    url: `store/${storeId}/account`,
    method: "POST",
    data,
  });

export const updateBankAccount = (data, storeId) =>
  request({
    url: `store/${storeId}/account`,
    method: "PUT",
    data,
  });
