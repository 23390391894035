export const sideBarMenus = [
  {
    id: 0,
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    id: 1,
    path: "/inventory",
    title: "Inventory",
  },
  {
    id: 2,
    path: "/order_history",
    title: "Order history",
  },
  // {
  //   id: 3,
  //   path: "/messages",
  //   title: "Messages",
  // },
  {
    id: 4,
    path: "/account",
    title: "Account",
  },
  {
    id: 5,
    path: "/wallet",
    title: "Wallet",
  },
  {
    id: 6,
    path: "/setting",
    title: "Settings",
  },
];
