import { request } from "../Utils";
const BASE_URL = "products";

export const getProducts = (storeId, payload) =>
  request({
    url: `store/${storeId}/product`,
    params: {
      ...payload,
    },
  });

export const getSellerProducts = (id) =>
  request({
    url: `sellers/${id}/products`,
  });

export const getProductDetails = (storeId, productId) =>
  request({
    url: `store/${storeId}/product/${productId}`,
    method: "GET",
  });

export const createProducts = (data, id) => {
  return request({
    url: `store/${id}/product`,
    method: "POST",
    data,
  });
};

export const deleteProduct = (id, productId) =>
  request({
    url: `store/${id}/product/${productId}`,
    method: "DELETE",
  });

export const editProduct = (data, id, productId) => {
  return request({
    url: `store/${id}/product/${productId}`,
    method: "PUT",
    data,
  });
};

export const toggleProductStatus = (payload) => {
  const { storeId, productId, status } = payload;
  const data = { isActive: status };
  return request({
    url: `store/${storeId}/product/${productId}/toggle_product_status`,
    method: "PUT",
    data,
  });
};


export const replaceProductImage = (data, storeId, productId) => {
  return request({
    url: `store/${storeId}/product/${productId}/images/replace`,
    method: "POST",
    data,
  });
};


export const deleteProductDiscount = (data, storeId, productId) => {
  return request({
    url: `store/${storeId}/product/${productId}/${storeId}/product/${productId}/discount`,
    method: "DELETE",
    data,
  });
};

export const createProductDiscount = (data, storeId, productId) => {
  return request({
    url: `store/${storeId}/product/${productId}/${storeId}/product/${productId}/discount`,
    method: "POST",
    data,
  });
};