export const topSellerCategories = [
  "Hair Care",
  "Wigs & Weaves",
  "Extension",
  "Braids",
  "Lipstick",
  "Tools & Appliance",
];

export const shoppingOptions = [
  {
    label: "Retailer",
    value: "Retailer",
  },
  {
    label: "Salon",
    value: "Salon",
  },
  {
    label: "Pharmacy",
    value: "Pharmacy",
  },
  {
    label: "Beauty Professional",
    value: "Beauty Professional",
  },
];
