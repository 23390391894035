import { lazy } from "react";
import { AuthLayout } from "../Layouts";

const Login = lazy(() => import("../Pages/Shared/SellerLogin"));
const Signup = lazy(() => import("../Pages/Shared/SellerSignUp"));
const ForgotPassword = lazy(() =>
  import("../Pages/Shared/SellerForgotPassword")
);
const SignUpPersonalInformation = lazy(() =>
  import("../Pages/Shared/SellerPersonalInformation")
);
const SignUpBusinessInformation = lazy(() =>
  import("../Pages/Shared/SellerBusinessInformation")
);
const SignUpCreateStore = lazy(() =>
  import("../Pages/Shared/SellerCreateStore")
);
const SignUpBusinessDocument = lazy(() =>
  import("../Pages/Shared/SellerBusinessDocument")
);
const Profile = lazy(() => import("../Pages/Private/Profile"));
const Inventory = lazy(() => import("../Pages/Private/Inventory/inventory"));
const CreateProduct = lazy(() =>
  import("../Pages/Private/Inventory/createNewProduct")
);
const EditProduct = lazy(() =>
  import("../Pages/Private/Inventory/EditProduct/editProduct")
);
const Messages = lazy(() => import("../Pages/Private/Messages"));
const Dashboard = lazy(() => import("../Pages/Private/Dashboard"));
const LandingPage = lazy(() => import("../Pages/Private"));
const Orders = lazy(() => import("../Pages/Private/Orders"));
const Account = lazy(() => import("../Pages/Private/Account"));
const OrderDetials = lazy(() => import("../Pages/Private/Orders/details"));

const EmailVerification = lazy(() => import("../Pages/Shared/emailVerification"));
const VerifyEmail = lazy(() => import("../Pages/Shared/verifyEmail"));

const ViewProduct = lazy(() =>
  import("../Pages/Private/Inventory/viewProductDetails")
);
const ErrorPage = lazy(() => import("../Ui_elements/ErrorPage"));
const Wallet = lazy(() => import("../Pages/Private/Wallet"));
const Settings = lazy(() => import("../Pages/Private/Settings"));

export const routes = [
  {
    path: "/",
    element: LandingPage,
  },
  {
    path: "/dashboard",
    element: Dashboard,
  },
  {
    path: "/profile",
    element: Profile,
  },
  {
    path: "/inventory",
    element: Inventory,
  },
  {
    path: "/inventory/create_product",
    element: CreateProduct,
  },
  {
    path: "/inventory/edit_product",
    element: EditProduct,
  },
  {
    path: "/inventory/product/:id",
    element: ViewProduct,
  },
  {
    path: "/order_history",
    element: Orders,
  },
  {
    path: "/order_history/order_details/:id",
    element: OrderDetials,
  },
  {
    path: "/messages",
    element: Messages,
  },
  {
    path: "/wallet",
    element: Wallet,
  },
  {
    path: "/account",
    element: Account,
  },
  {
    path: "/setting",
    element: Settings,
  },
  {
    path: "/*",
    element: () => <ErrorPage />,
    hasLayout: true,
  },
];

export const authRoutes = [
  {
    path: "/",
    element: () => (
      <AuthLayout>
        <Signup />
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/login",
    element: () => (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    hasLayout: true,
  },

  
  {
    path: "/signup",
    element: () => (
      <AuthLayout>
        <Signup/>
      </AuthLayout>
    ),
    hasLayout: true,
  },

  
  {
    path: "/forgot-password",
    element: () => <ForgotPassword />,
    hasLayout: true,
  },
  {
    path: "/email-verification",
    element: () => (
      <AuthLayout>
        <EmailVerification />
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/verify-email/:id",
    element: () => (
      <AuthLayout>
        <VerifyEmail />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/signup/personal_information",
    element: () => (
      <AuthLayout>
        <SignUpPersonalInformation />
      </AuthLayout>
    ),
    hasLayout: true,
  },

  {
    path: "/signup/business_information",
    element: () => (
      <AuthLayout>
        <SignUpBusinessInformation />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/signup/create_store",
    element: () => (
      <AuthLayout>
        <SignUpCreateStore />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/signup/business_document",
    element: () => (
      <AuthLayout>
        <SignUpBusinessDocument />
      </AuthLayout>
    ),
    hasLayout: true,
  },
  {
    path: "/*",
    element: () => <ErrorPage />,
    hasLayout: true,
  },
];
