import React from "react";
import { styled } from "styled-components";
import authImage from "../../Assets/Images/seller_signup.png";
import authGradient from "../../Assets/Images/sign_up_gradient.png";
import { Logo } from "../../Assets/Svgs";
import { devices } from "../../Utils/mediaQueryBreakPoints";

export const AuthLayout = ({ children }) => {
  return (
    <Container>
      <LeftSection>
        <BgImage src={authImage} />
        <AuthBgGradient src={authGradient} />
        <a
          href="https://app.gingerme.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AuthLogo />
        </a>
        <LeftTextWrapper>
          {/* <Tag>Seller</Tag> */}
          <LeftTitle>Reach independent businesses across the world</LeftTitle>
          <LeftSubtitle>
            Sign up to start selling in Ginger B2B marketplace. No set-up fees.
            No commitments.
          </LeftSubtitle>
        </LeftTextWrapper>
      </LeftSection>
      <RightSection>
        <ContentWrapper>{children}</ContentWrapper>
      </RightSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  padding: 48px 20px 64px 74px;
  overflow: hidden;

  @media ${devices.tablet} {
    width: 100%;
    padding: 20px;
    height: 40%;
  }
`;

const AuthBgGradient = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;
const Tag = styled.div`
  padding: 5px 10px;
  background-color: var(--primary-color);
  width: fit-content;
  font-size: 1.4vw;
  font-weight: 500;
  border-radius: 10px;
  color: white;

  @media ${devices.tablet} {
    font-size: 3vw;
    padding: 2px 5px;
  }
`;

const AuthLogo = styled(Logo)`
  width: 167.805px;
  height: 32px;
  flex-shrink: 0;

  @media ${devices.tablet} {
    width: 150px !important;
    height: 30px !important;
  }
`;
const LeftTextWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 80px 64px 80px;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);

  @media ${devices.tablet} {
    padding: 20px;
  }
`;

const LeftTitle = styled.h2`
  font-size: 2.8vw;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  color: #fefefe;

  @media ${devices.tablet} {
    font-size: 5vw !important;
    line-height: 1.2 !important;
  }

  @media ${devices.laptop} {
    font-size: 40px;
    line-height: 40px;
  }
`;

const LeftSubtitle = styled.p`
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fefefe;
  @media ${devices.tablet} {
    font-size: 0.8rem;
    line-height: 1.2;
  }
`;

const RightSection = styled.div`
  position: relative;
  width: 53%;
  background-color: #fffbf7;

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 740px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 56px 80px;
  background-color: white;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 20vw;
  transform: translate(-50%, -50%);

  @media ${devices.tablet} {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    max-width: 100%;
    max-height: 60vh !important;
    padding: 20px;
  }
`;
