import Password from "../sections/Password";
import Personal from "../sections/personal";

export const profileHash = {
  personal: `#personal_information`,
  security: `#security`,
};

export const profileTabData = [
  {
    label: `Personal Information`,
    hashName: profileHash.personal,
    Body: Personal,
  },
  {
    label: `Security`,
    hashName: profileHash.security,
    Body: Password,
  },
];
