import styled from "styled-components";
import { Link } from "react-router-dom";
import { devices } from "../../Utils";

export const Chip = ({ children, to, isActive, ...otherProps }) => {
  return (
    <Container to={to} active={isActive} {...otherProps}>
      <p>{children}</p>
    </Container>
  );
};

const Container = styled(Link)`
  border: 1px solid #e8e8e8;
  background-color: ${({ active }) =>
    active ? "var(--black)" : "transparent"};
  transition: all 0.3s ease;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 100px;
  cursor: pointer;
  p {
    transition: all 0.3s ease;
    color: ${({ active }) => (active ? "white" : "var(--black)")};
    white-space: nowrap;

  }
  &:hover {
    border: 1px solid var(--black);
  }

  @media ${devices.mobileL} {
    p {
      font-size: 0.7rem;
    }
  }
`;
