import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GButton, GSelectField, GTextField } from "../../../../Ui_elements";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonalSchema } from "../../Account/components/validation";
import { countries, devices } from "../../../../Utils";
import { useApiGet, useApiSend } from "../../../../Hooks";
import { toast } from "react-hot-toast";
import { getUser, updateUser } from "../../../../Urls";
import { useQueryClient } from "@tanstack/react-query";

const Personal = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const queryClient = useQueryClient();

  const { data: userData, isLoading: isLoadingUser } = useApiGet(
    ["get-user-data"],
    () => getUser(),
    {
      enabled: true,
    }
  );

  const { mutate, isPending } = useApiSend(
    updateUser,
    () => {
      toast.success("Your information has been updated successfully.");
      queryClient.invalidateQueries(["get-user-data"]);
    },
    () => {
      toast.error(`Something went wrong`);
    }
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(PersonalSchema),
  });

  useEffect(() => {
    if (userData) {
      const defaultCountry = countries.find(
        (x) => x.value === userData?.country
      );

      setValue("firstName", userData.firstName);
      setValue("lastName", userData.lastName);
      setValue("phoneNumber", userData.phoneNumber);
      setValue("country", defaultCountry);
    }
  }, [userData, setValue]);

  const isDirty = !!Object.keys(dirtyFields).length;

  const onSubmit = (values) => {
    const body = {
      ...values,
      country: values?.country?.value,
    };
    mutate(body);
  };

  return (
    <Container>
      <Top>
        <Title>Personal information</Title>
        <Subtitle>Information about you</Subtitle>
      </Top>
      <Bottom>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextWrapper>
            <Label>First name</Label>
            <GTextField
              id="firstName"
              placeholder="Enter first name"
              inputType="text"
              name="firstName"
              register={register}
              error={errors.firstName}
              errorText={errors.firstName && errors.firstName.message}
              required
              isDisabled={isDisabled}
            />
          </TextWrapper>
          <TextWrapper>
            <Label>Last name</Label>
            <GTextField
              id="lastName"
              placeholder="Enter last name"
              inputType="text"
              name="lastName"
              register={register}
              error={errors.lastName}
              errorText={errors.lastName && errors.lastName.message}
              required
              isDisabled={isDisabled}
            />
          </TextWrapper>
          <TextWrapper>
            <Label>Phone number</Label>
            <GTextField
              id="phoneNumber"
              placeholder="Enter phone number"
              inputType="text"
              name="phoneNumber"
              register={register}
              error={errors.phoneNumber}
              errorText={errors.phoneNumber && errors.phoneNumber.message}
              required
              isDisabled={isDisabled}
            />
          </TextWrapper>
          <SelectWrapper>
            <Label>Country</Label>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <GSelectField
                  {...field}
                  placeholder="Select a country"
                  options={countries}
                  id="country"
                  disabled={isDisabled}
                  searchable={true}
                  isError={!!errors?.country}
                  errorText={errors?.country && errors?.country?.message}
                />
              )}
            />
          </SelectWrapper>
          {isDirty ? (
            <GButton
              label={`Save changes`}
              width={`204px`}
              isLoading={isPending}
            />
          ) : (
            <EditBtn onClick={() => setIsDisabled(false)}>Edit</EditBtn>
          )}
        </Form>
      </Bottom>
    </Container>
  );
};

export default Personal;

const Container = styled.div`
  width: 100%;
  flex-shrink: 0;
  border: 1px solid var(--Black-50, #e8e8e8);
  background: var(--White, #fefefe);
`;

const Top = styled.div`
  padding: 30px 35px 20px;
  border-bottom: 1px solid #e8e8e8;

  @media ${devices.tablet} {
    padding-left: 10px;
  }
`;

const Title = styled.p`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
`;

const Subtitle = styled.p`
  color: var(--Black-500, #151515);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin-top: 7px;
  opacity: 0.5;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 35px 35px 40px;

  @media ${devices.tablet} {
    padding: 10px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 46px 52px;

  @media ${devices.tablet} {
    justify-content: start;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 47%;

  @media ${devices.laptop} {
    width: 46%;
  }

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 47%;
  margin-top: -2px;

  @media ${devices.laptop} {
    width: 46%;
  }

  @media ${devices.tablet} {
    width: 100%;
  }
`;

const Label = styled.p`
  color: var(--Black-100, #b6b6b6);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

const EditBtn = styled.p`
  color: var(--Primary-500, #ff4623);
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 26.4px */
  cursor: pointer;

  @media ${devices.laptop} {
    font-size: 18px;
  }
`;
